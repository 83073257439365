import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [videotitle, setVideostitle] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    { src: "/SLIDE1.png", alt: "Gallery 1" },
    { src: "/slide2.png", alt: "Gallery 2" },
    { src: "/slide3.png", alt: "Gallery 3" },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleArrowClick = () => {
    setShowGallery(!showGallery);
    setShowVideos(!showVideos);
    setVideostitle(!videotitle);
  };

  // Automatically transition slides
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 3000); // Slide transition every 3 seconds
    return () => clearInterval(timer); // Cleanup on component unmount
  }, [slides.length]);

  const handleImageClick = (src) => {
    const imgWindow = window.open("", "_blank");
    imgWindow.document.write(
      `<html><head><title>Image</title></head><body style="margin:0;display:flex;justify-content:center;align-items:center;height:100vh;background:black;"><img src="${src}" style="max-width:100%;max-height:100%;"/></body></html>`
    );
  };

  return (
    <div className="App">
      <header className="header">
        <div className="logo">
          <img src="/heav.png" alt="Logo" />
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          &#9776;
        </button>
        <nav className={`menu ${menuOpen ? "open" : ""}`}>
          <button className="close-menu" onClick={toggleMenu}>
            &times;
          </button>
          <ul>
            <li><a href="#services-section">Services</a></li>
            <li><a href="#workflow-section">Workflow</a></li>
            <li><a href="#next-gen-section">Next Gen Tech</a></li>
            <li><a href="#reach-out-section">Reach Out</a></li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
     {/* Hero Section */}
     <section className="hero">
  <div className="overlay">
    <div className="content">
      <h1 className="hero-title">
        <span className="typing-effect">YOUR DIGITAL TRANSFORMATION PARTNER</span>
       
      </h1>
      <p className="hero-description">
        At <span className="highlight">Heave</span>, we power your business growth with cutting-edge tech and generative AI, creating smart solutions that drive success in a digital-first world.
      </p>
      <a href="https://wa.me/" className="contact-button">
        <i className="fab fa-whatsapp"></i> Contact Us
      </a>
    </div>
  </div>
</section>





      {/* Services Section */}
      <section id="services-section" className="services-section">
  <h2 className="gallery-title">SERVICES</h2>
  <div className="services-container">
    {/* Web Development Service */}
    <div className="service-box">
      <div className="service-icon">
        <img src="/development-2.png" alt="Web Development Icon" />
      </div>
      <h3>Web Development</h3>
      <p style={{color: 'grey'}}>We develop scalable web pages to transform your idea into a creative web platform that represents your brand effectively.</p>

    </div>

    {/* Mobile App Development */}
    <div className="service-box">
      <div className="service-icon">
        <img src="/mobl.png" alt="Mobile App Development Icon" />
      </div>
      <h3>Mobile App Development</h3>
      <p style={{color: 'grey'}}>Our team creates user-friendly mobile applications that elevate your business and engage your customers.</p>
    </div>

    {/* Data Analytics Service */}
    <div className="service-box">
      <div className="service-icon">
        <img src="/bar-chart.png" alt="Data Analytics Icon" />
      </div>
      <h3>Data Analytics</h3>
      <p style={{color: 'grey'}}>Our data analytics services help you unlock valuable insights from your data, enabling better decision-making and strategic growth.</p>
    </div>
  </div>
</section>

      {/* Workflow Section */}
      <section id="workflow-section" className="workflow-section">
        <h2 className="workflow-title">OUR WORKFLOW</h2>
        <p className="workflow-description">
          Our Continuous Delivery process: Develop, Test, Integrate, and Deploy.
        </p>

        <div className="timeline">
          <div className="timeline-step" id="step1">
            <div className="timeline-marker">
              <img src="/analysis.png" alt="Project Analysis Icon" />
            </div>
            <div className="timeline-content">
              <h3>Project Analysis</h3>
              <p style={{color: 'grey'}}>We first understand your idea and analyze the project.</p>
            </div>
          </div>

          <div className="timeline-step" id="step2">
            <div className="timeline-marker">
              <img src="/manufacturing.png" alt="Design & Production Icon" />
            </div>
            <div className="timeline-content">
              <h3>Design & Production</h3>
              <p style={{color: 'grey'}}>We complete the development process with precise design.</p>
            </div>
          </div>

          <div className="timeline-step" id="step3">
            <div className="timeline-marker">
              <img src="/cloud.png" alt="Testing Icon" />
            </div>
            <div className="timeline-content">
              <h3>Testing</h3>
              <p style={{color: 'grey'}}>Our testing team ensures quality.</p>
            </div>
          </div>

          <div className="timeline-step" id="step4">
            <div className="timeline-marker">
              <img src="/rocket.png" alt="Launch Icon" />
            </div>
            <div className="timeline-content">
              <h3>Launch</h3>
              <p style={{color: 'grey'}}>We launch your product to the world.</p>
            </div>
          </div>

          <div className="timeline-step" id="step5">
            <div className="timeline-marker">
              <img src="/bullhorn.png" alt="Marketing Icon" />
            </div>
            <div className="timeline-content">
              <h3>Marketing</h3>
              <p style={{color: 'grey'}}>The product is marketed for visibility.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Gallery Section */}
      <section id="gallery-section" className="gallery-section">
        <div className="gallery-container">
          <div className="gallery-slider">
            {slides.map((slide, index) => (
              <img
                key={index}
                src={slide.src}
                alt={slide.alt}
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                onClick={() => handleImageClick(slide.src)}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Video Title Section */}
      {videotitle && <h2 className="gallery-title">DEPLOYMENTS</h2>}

      {/* Video Section */}
      {showVideos && (
        <section className="video-content-section">
          <div className="box left-box glow">
            <div className="video-container">
              <iframe
                width="30%"
                height="130"
                src="https://www.youtube.com/embed/video-id1"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="box center-box glow">
            <div className="video-container">
              <iframe
                width="40%"
                height="140"
                src="https://www.youtube.com/embed/video-id2"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="box right-box glow">
            <div className="video-container">
              <iframe
                width="40%"
                height="130"
                src="https://www.youtube.com/embed/video-id3"
                title="YouTube video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </section>
      )}

      {/* Footer */}
      <footer id="reach-out-section" className="footer">
        <div className="footer-container">
          <div className="footer-left">
            <ul className="footer-nav">
              <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i> LinkedIn</a></li>
              <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i> Instagram</a></li>
              <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i> Twitter</a></li>
            </ul>
          </div>
          <div className="footer-right">
            <img src="heav.png" alt="Company Logo" className="footer-logo" />
            <p>Contact Us: <a href="mailto:reachout@heave.com">connect@heave.in</a></p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
